
import { Component, Vue } from 'vue-property-decorator';
import SingleHeader from '@/components/singleHeader.vue';
import SingleFooter from '@/components/singleFooter.vue';

@Component({
    name: "DeveloperCenter",
    components: {
        SingleHeader,
        SingleFooter,
    },
})
export default class DeveloperCenter extends Vue {
    private apiProxy = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private resourceTab: any = {};
    private category: any = '';
    private searchParam: any = {
        keyword: '',
    };
    private labelList: any = [];
    private showList: any = [];
    private gotoDeveloperCenter() {
        this.$router.push('/developerCenter');
    }
    private created() {
        if (this.$route.fullPath.indexOf('category=') > -1) {
            this.category = unescape(this.$route.query.category + '');
            this.getResourceList();
            this.getLabelList();
        }
    }
    // vue的mounted方法中, 写设置页面滚动条的这个方法
    private mounted() {
        // 切换页面时滚动条自动滚动到顶部
        window.scrollTo(0, 0);
    }
    private getResourceList() {
        const searchParam: any = {};
        const arr: any = [];
        this.labelList.forEach((item: any, idx: number) => {
            if (this.searchParam['label_' + idx] && this.searchParam['label_' + idx] !== undefined) {
                arr.push(this.searchParam['label_' + idx]);
                delete searchParam['label_' + idx];
            }
        });
        searchParam.tagList = arr;
        this.$httpService.getData(searchParam, `/apiProxy/api/frontend/resource/${this.category}`)
        .then((res: any) => {
            this.resourceTab = res;
            this.showList = [];
            if (this.resourceTab.editions && this.resourceTab.editions.length > 0) {
                this.resourceTab.editions.forEach((item: any) => {
                    if (this.showList.length === 0 && item.related && item.related.length > 0) {
                        this.handleHide(item.id);
                    }
                });
            }
        });
    }
    private getLabelList() {// 获取列表数据
        this.$httpService.getData({}, `/apiProxy/api/frontend/resource/${this.category}/tag`)
        .then((res: any) => {
            if (res) {
                this.labelList = res.filter((el: any) =>  {
                    return el.status === 1 && el.tagList.length > 0;
                });
            } else {
                this.labelList = [];
            }
        });
    }
    private async downLoadFrame(row: any, fileName: any = null) {
        if (!await this.$isLogin()) {
            this.loginTips();
            return;
        }
        if (row.link.includes('http://') || row.link.includes('https://')) {
            window.open(row.link);
            return;
        }
        this.$httpService.getData({}, `/apiProxy/api/frontend/resource/editions/${row.id}/url`)
        .then((res: any) => {
            // const attNameArr = res.split('?attname=');
            // let fileText = null;
            // if (attNameArr.length === 2) {
            //     try {
            //         const fileArr = fileName.split(".");
            //         fileText = fileArr[0];
            //     } catch (err) {
            //         fileText = null;
            //     }
            // }
            // this.downloadFile(res, fileText);
            if (res && res !== '') {
                const domain = process.env.VUE_APP_URL + '';
                const baseDomain = domain.indexOf('//cloud') > -1 ? domain : 'https://scloud.morewis.com';
                window.open(baseDomain + res);
            }
        });
    }
    private loginTips() {
        this.$confirm('请登录后操作', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        }).then(() => {
            this.$router
            .push({
                path: '/login',
                query: {
                    redirect: window.location.href,
                },
            }).catch((err) => err);
        }).catch(() => {
            // ...
        });
    }
    private onSearch() {
        // ...
    }
    private getFileSize(size: any) {
        if (!size) {
            return "0B";
        }
        const num = 1024.00; // byte

        if (size < num) {
            return size + "B";
        } else if (size < Math.pow(num, 2)) {
            return (size / num).toFixed(2) + "K"; // kb
        } else if (size < Math.pow(num, 3)) {
            return (size / Math.pow(num, 2)).toFixed(2) + "M"; // M
        } else if (size < Math.pow(num, 4)) {
            return (size / Math.pow(num, 3)).toFixed(2) + "G"; // G
        } else {
            return (size / Math.pow(num, 4)).toFixed(2) + "T"; // T
        }
    }
    //  下载附件
    private async downloadFile(fileUrl: any, fileName: any = null) {
        this.$message.success('文件下载中，请稍等...');
        if (!fileName) {
            fileName = new Date().getTime();
        }
        const response = await fetch(this.apiProxy  + fileUrl);
        const blob = await response.blob();
        this.triggerDownload(URL.createObjectURL(blob), fileName);
    }
    private triggerDownload(blob: any, fileName: string) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = blob;
        a.download = fileName;
        a.click();
        document.body.removeChild(a);
    }
    private handleHide(id: any) {
        const isSome = this.showList.some((item: any) => item === id);
        if (!isSome) {
            this.showList.push(id);
        } else {
            this.showList = this.showList.filter((item: any) => item !== id);
        }
    }
    private selectTagHandle(value: any) {
        this.getResourceList();
    }
}
